@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* LOGIN */
.main-container {
  padding: 30px 40px;
  background: #fff;
  border-radius: 10px;
  /* height: 100%; */
}

.main-container[data-page="Login"] {
  height: 100%;
}

.main-container[data-page="Forgot"] {
  height: auto;
}

.main-switch-btn {
  width: 100%;
  margin-bottom: 30px;
}

.main .main-switch-btn Link {
  font-weight: 500;
}

.switch-active {
  background: #4caf50 !important;
  color: white !important;
  font-weight: 700 !important;
}

.switch-btn-login,
.switch-btn-register {
  border: 2px solid #4caf50;
  height: 25px;
  transition: background 0.5s, color 0.5s, padding-right 0.5s, padding-left 0.5s;
  padding: 1px;
  font-size: 12px;
}

.switch-btn-login {
  border-radius: 15px 0 0 15px;
  color: #4caf50;
}

.switch-btn-login:hover {
  color: #fff;
  background: #4caf50;
  font-weight: bold;
  padding-left: 10px;
}

.switch-btn-register {
  border-radius: 0 15px 15px 0;
  color: #4caf50;
}

.switch-btn-register:hover {
  color: #fff;
  background: #4caf50;
  font-weight: bold;
  padding-right: 10px;
}

.main-form input[type="email"],
input[type="password"],
input[type="text"] {
  height: 40px;
  font-size: 12px;
}

.rbt input {
  padding: 0 1rem;
}

.main-form label {
  font-weight: bold;
  font-size: 12px;
  color: #505254;
  cursor: pointer;
  margin-bottom: 0.3rem;
}

.main-form .form-check label {
  font-size: 12px;
  color: #505254;
  cursor: pointer;
}

.main-form .forgot-password {
  font-size: 12px;
  text-align: right;
  padding: 5px 3px;
  font-weight: bold;
}

.main-form button[type="submit"] {
  background: #4caf50;
  color: white;
  border-radius: 30px;
  height: 40px;
  font-weight: 700;
  transition: font-weight 0.5;
  transition: background 0.5s;
  font-size: 14px;
}

.main-form button[type="submit"]:hover {
  background: #4caf50;
}

.main-link {
  font-weight: bold;
}

.input-password {
  position: relative;
}

.input-password .input-group-append {
  right: 0;
  position: absolute;
}

.input-password .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
  height: 40px;
  background: transparent;
  border: transparent;
}

.form-check-input:checked {
  background-color: #4caf50;
  border-color: #4caf50;
}

.auth-describe h5 {
  font-weight: bold;
}

.auth-describe p {
  font-size: 13px;
}

.toggle-password {
  background: #fff;
  border-left: none;
}

.form-title h5 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
}

.form-title p {
  font-size: 13px;
}

span[data-testid="not-have-account"] {
  font-size: 13px;
  color: #505254;
}

.link {
  text-decoration: none;
  color: #4caf50;
  transition: color 0.5s ease-in-out;
  font-weight: bold;
}

.link:hover {
  color: #4caf50;
}

.back {
  font-size: 13px;
}

@media (max-width: 767px) {
  .main-container {
    padding: 30px;
    margin-bottom: 3rem;
  }

  .switch-btn-login,
  .switch-btn-register {
    height: 30px;
    padding: 4px;
  }

  .auth-describe {
    display: none;
  }
}

.school-list-container {
  text-align: center;
}

.school-list-container h4 {
  /* margin: 80px 0; */
  margin-top: 50px;
}

.carousel-container {
  width: 100%;
}

.logo-img {
  border-radius: 15px;
  height: 120px;
  width: 120px;
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.logo-img img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.item p {
  font-size: 13px;
}

.slick-slider {
  padding: 0 10vh;
}

.slick-next {
  right: 20px;
}

.slick-prev {
  left: 20px;
}

.slick-prev,
.slick-next {
  top: 35% !important;
  opacity: 0;
  transition: 0.5s;
}

.slick-slider:hover .slick-prev {
  opacity: 1;
}

.slick-slider:hover .slick-next {
  opacity: 1;
}

@media (max-width: 991px) {
  .logo-img {
    width: 100px;
    height: 100px;
  }

  .logo-img img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .logo-img {
    width: 100px;
    height: 100px;
  }

  .logo-img img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }

  .slick-slider {
    padding: 0 5vh;
  }
}

.mr-3 {
  margin-right: 15px !important;
}

.data-container .data-box {
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  margin-top: 50px;
  border-radius: 10px;
  display: flex;
}

.data-container .data-box .data-item {
  height: 100%;
  text-align: center;
  width: 33.3%;
  padding: 15px 0;
  font-size: 30px;
}

.border-white {
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
}

.fieldset {
  border: 2px groove #fff !important;
  padding: 0.3em !important;
  border-radius: 5px;
  height: auto;
  font-size: 30px;
}

.legend {
  width: auto;
  padding: 0 5px;
  border-bottom: none;
  text-align: right;
  font-size: 12px;
  margin-bottom: 0px !important;
}

.data-mobile {
  display: none !important;
}

@media (max-width: 991px) {
  .data-container .data-box .data-item .fieldset {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .data-container .data-box {
    flex-direction: column;
  }

  .data-container .data-box .data-item {
    width: 100%;
  }

  .border-top {
    border-top: 3px solid #fff;
  }

  .data {
    display: none;
  }

  .data-mobile {
    display: block !important;
  }
}

.footer {
  background: rgba(255, 255, 255, 1);
  padding: 8px 13vh;
  z-index: 99999;
  position: absolute;
}

.footer-list {
  flex-wrap: wrap;
  align-self: center;
  margin-bottom: 0;
  justify-content: center;
}

.footer-list li {
  margin: 0px 10px;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.footer-link {
  color: #505254;
  font-size: 13px;
  text-decoration: none;
}

.footer-link:hover {
  color: #505254;
  font-weight: bold;
  text-decoration: underline;
}

.copyright {
  font-size: 13px;
  text-align: center;
  color: #505254;
  font-weight: bold;
  justify-content: center;
}

@media (max-width: 991px) {
  .footer-list {
    justify-content: center;
  }

  .copyright {
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .footer {
    padding: 10px 10px;
  }

  .footer-list {
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer-list .footer-link {
    font-size: 12px;
  }

  .footer-list li {
    text-align: center;
  }

  .copyright {
    font-size: 11px;
    justify-content: center;
  }

  .footer .link-list {
    display: none !important;
  }
}

.data-container {
  padding: 5vh 25vh;
  width: 100%;
  color: #fff;
}

.ppdb:hover {
  color: #cdcdcd;
}

.login:hover {
  background: #4caf50;
}

.title-mobile {
  display: none;
}

#desktop {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-mobile {
    display: block;
  }

  #desktop {
    display: none;
  }
}


.hero-background {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    /* filter: blur(2px); */
}

.hero-bg-mobile {
    display: none;
}

.hero {
    background: transparent;
    min-height: 100vh;
    margin: 0;
    position: relative;
    z-index: 2;
}

.hero .content-container {
    display: flex;
    padding: 15vh 10vh;
    min-height: 100vh;
}
.hero .content-container .left-side {
    height: 435px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    flex-wrap: wrap;

}

.hero h1 {
    font-weight: bold;
}

.hero .logo img {
    width: 100px;
    height: 100px;
}

.hero h5 {
    font-size: 1.75rem;
}

.hero .left-side .title-container {
    width: 88%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hero .right-side {
    position: relative;
    overflow-y: scroll;
}

.hero .right-side[data-page="Login"] {
    min-height: 435px;
}

.hero .right-side[data-page="Forgot"] {
    height: 435px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.right-side::-webkit-scrollbar {
	display: none;
}

.slogan {
    /* margin-top: 25%; */
    text-align: justify;
    font-family: 'PT Sans', sans-serif;
    font-weight: 300;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.slogan p {
    font-size: 1.7rem;
    margin-bottom: 0;
    font-weight: 300;
    line-height: normal;
}

.data-container {
    padding: 5vh 25vh;
    width: 100%;
    color: #fff;
}

.mr-3 {
    margin-right: 15px !important;
}

.border {
    border: 1px solid #fff;
}

.logo-title {
    display: none;
}

.header {
    display: flex;
    justify-content: space-evenly;
    font-size: 150%;
    padding-top: 50px; 
    padding-right: 50px;
}

.title {
    display: flex;
    padding: 5vh;
    align-items: center; 
    justify-content: center;
}

.title-mobile {
    display: none;
}

.deskripsi {
    display: flex;
}

.menu {
    display: none;
}

@media (max-width: 991px) {
    .hero .content-container {
        padding: 5vh 5vh;
        min-height: 60vh;
    }

    .hero h1 {
        font-size: 1.7rem;
    }

    .hero h5 {
        font-size: 1.5rem;
    }

    .slogan p {
        font-size: 1.3rem;
    }

    .hero .content-container .left-side {
        align-self: center;

    }

    .hero .left-side .title-container {
        position: relative;
        width: 85%;
    }

    .hero .right-side {
        position: relative;
        align-self: center;
    }

    .slogan {
        margin-top: 5vh;
        text-align: justify;
    }

    .data-container {
        padding: 0 5vh;
    }
}

@media (max-width: 767px) {

    .menu {
        display: block;
        text-align:'right';
        color:'white';
    }

    .header {
        display: none;
    }

    .title {
        display: none;
    }

    .title-mobile {
        display: block;
        text-align: center;
        /* padding-top: 50px; */
        /* padding-bottom: 20px; */
    }

    .deskripsi {
        display: none;
    }

    .hero-bg-mobile {
        display: block;
    }

    .hero .content-container {
        display:block;
        padding: 20px;
    }

    .main-container[data-page="Login"] {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .hero .right-side[data-page="Login"], .hero .right-side[data-page="Register"], .hero .right-side[data-page="Forgot"] {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .hero .right-side[data-page="Forgot"] {
        justify-content: flex-start;
    }

    .hero .left-side[data-page="Login"], .hero .left-side[data-page="Register"] , .hero .left-side[data-page="Forgot"] {
        display: none;
    }

    .slogan {
        text-align: justify;
    }

    .deskripsi {
        width: 70%;
    }

    .data-container {
        padding: 0;
        flex-direction: column;
    }

    .logo {
        margin-right: 10px;
    }

    .logo-title {
        display: flex;
        color: #fff;
        justify-content: center;
    }

    .logo-title .logo img {
        width: 75px;
        height: 75px;
    }

    .logo-title .deskripsi {
        margin-left: 10px;
    }

    .logo-title h2 {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
    }
    
}
.error-notfound img {
    height: 300px;
    width: 300px;
}

.error-notfound button {
    transition: background-color 0.5s, color 0.5s;
}

.error-notfound button:hover {
    background-color: #303F9F;
    color: #fff;
}

@media (max-width: 767px) {
    .error-notfound {
        padding: 10px;
    }
}
body {
  margin: 0;
  font-family: -apple-system, nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #2A4458;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.paper {
  background: transparent;
}

.bold {
    font-weight: bold;
}

section {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

/* OTHER */
.loading-container {
    font-size: 13px;
}

.loading {
    height: 14px;
    width: 14px;
}

.invalid-feedback {
    padding-left: 5px;
    font-size: 13px;
}

.show-hide {
    background: transparent;
    border: none;
}

.icon-btn {
    cursor: pointer;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none;
}
