.school-list-container {
  text-align: center;
}

.school-list-container h4 {
  /* margin: 80px 0; */
  margin-top: 50px;
}

.carousel-container {
  width: 100%;
}

.logo-img {
  border-radius: 15px;
  height: 120px;
  width: 120px;
  background: white;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.logo-img img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.item p {
  font-size: 13px;
}

.slick-slider {
  padding: 0 10vh;
}

.slick-next {
  right: 20px;
}

.slick-prev {
  left: 20px;
}

.slick-prev,
.slick-next {
  top: 35% !important;
  opacity: 0;
  transition: 0.5s;
}

.slick-slider:hover .slick-prev {
  opacity: 1;
}

.slick-slider:hover .slick-next {
  opacity: 1;
}

@media (max-width: 991px) {
  .logo-img {
    width: 100px;
    height: 100px;
  }

  .logo-img img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .logo-img {
    width: 100px;
    height: 100px;
  }

  .logo-img img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
  }

  .slick-slider {
    padding: 0 5vh;
  }
}
