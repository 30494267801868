.data-container {
  padding: 5vh 25vh;
  width: 100%;
  color: #fff;
}

.ppdb:hover {
  color: #cdcdcd;
}

.login:hover {
  background: #4caf50;
}

.title-mobile {
  display: none;
}

#desktop {
  display: inline-block;
}

@media (max-width: 768px) {
  .title-mobile {
    display: block;
  }

  #desktop {
    display: none;
  }
}
